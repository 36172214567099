
import { NavBar } from "../../components";
import "./coming-soon.scss";

export const ComingSoon:React.FC = () => {
    return (
        <>
        <NavBar/>
        <section className="ss-coming-soon">
             COMING SOON
        </section>
        </>
    )
}